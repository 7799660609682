// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compare-items-js": () => import("./../../../src/pages/compare-items.js" /* webpackChunkName: "component---src-pages-compare-items-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-covid-index-jsx": () => import("./../../../src/pages/covid/index.jsx" /* webpackChunkName: "component---src-pages-covid-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-accessibility-js": () => import("./../../../src/pages/misc/accessibility.js" /* webpackChunkName: "component---src-pages-misc-accessibility-js" */),
  "component---src-pages-misc-cookie-page-js": () => import("./../../../src/pages/misc/cookiePage.js" /* webpackChunkName: "component---src-pages-misc-cookie-page-js" */),
  "component---src-pages-my-account-billing-address-js": () => import("./../../../src/pages/my-account/billing-address.js" /* webpackChunkName: "component---src-pages-my-account-billing-address-js" */),
  "component---src-pages-my-account-change-password-js": () => import("./../../../src/pages/my-account/change-password.js" /* webpackChunkName: "component---src-pages-my-account-change-password-js" */),
  "component---src-pages-my-account-customer-address-js": () => import("./../../../src/pages/my-account/customer-address.js" /* webpackChunkName: "component---src-pages-my-account-customer-address-js" */),
  "component---src-pages-my-account-notifications-js": () => import("./../../../src/pages/my-account/notifications.js" /* webpackChunkName: "component---src-pages-my-account-notifications-js" */),
  "component---src-pages-my-account-shipping-address-js": () => import("./../../../src/pages/my-account/shipping-address.js" /* webpackChunkName: "component---src-pages-my-account-shipping-address-js" */),
  "component---src-pages-my-account-view-orders-js": () => import("./../../../src/pages/my-account/view-orders.js" /* webpackChunkName: "component---src-pages-my-account-view-orders-js" */),
  "component---src-pages-products-banking-products-index-js": () => import("./../../../src/pages/products/banking-products/index.js" /* webpackChunkName: "component---src-pages-products-banking-products-index-js" */),
  "component---src-pages-products-business-cheques-index-js": () => import("./../../../src/pages/products/business-cheques/index.js" /* webpackChunkName: "component---src-pages-products-business-cheques-index-js" */),
  "component---src-pages-products-business-forms-index-js": () => import("./../../../src/pages/products/business-forms/index.js" /* webpackChunkName: "component---src-pages-products-business-forms-index-js" */),
  "component---src-pages-products-custom-printing-index-js": () => import("./../../../src/pages/products/custom-printing/index.js" /* webpackChunkName: "component---src-pages-products-custom-printing-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-logo-design-index-js": () => import("./../../../src/pages/products/logo-design/index.js" /* webpackChunkName: "component---src-pages-products-logo-design-index-js" */),
  "component---src-pages-products-marketing-materials-index-js": () => import("./../../../src/pages/products/marketing-materials/index.js" /* webpackChunkName: "component---src-pages-products-marketing-materials-index-js" */),
  "component---src-pages-products-office-supplies-index-js": () => import("./../../../src/pages/products/office-supplies/index.js" /* webpackChunkName: "component---src-pages-products-office-supplies-index-js" */),
  "component---src-pages-products-signature-packaging-index-js": () => import("./../../../src/pages/products/signature-packaging/index.js" /* webpackChunkName: "component---src-pages-products-signature-packaging-index-js" */),
  "component---src-pages-sales-resources-index-js": () => import("./../../../src/pages/sales-resources/index.js" /* webpackChunkName: "component---src-pages-sales-resources-index-js" */),
  "component---src-pages-sales-resources-promotions-index-js": () => import("./../../../src/pages/sales-resources/promotions/index.js" /* webpackChunkName: "component---src-pages-sales-resources-promotions-index-js" */),
  "component---src-pages-sales-resources-sales-material-index-js": () => import("./../../../src/pages/sales-resources/sales-material/index.js" /* webpackChunkName: "component---src-pages-sales-resources-sales-material-index-js" */),
  "component---src-pages-sales-resources-sales-material-sell-sheets-index-js": () => import("./../../../src/pages/sales-resources/sales-material/sell-sheets/index.js" /* webpackChunkName: "component---src-pages-sales-resources-sales-material-sell-sheets-index-js" */),
  "component---src-pages-sales-resources-trade-shows-events-index-js": () => import("./../../../src/pages/sales-resources/trade-shows-events/index.js" /* webpackChunkName: "component---src-pages-sales-resources-trade-shows-events-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-seller-registration-js": () => import("./../../../src/pages/seller-registration.js" /* webpackChunkName: "component---src-pages-seller-registration-js" */),
  "component---src-pages-tools-resources-customer-service-topics-js": () => import("./../../../src/pages/tools-resources/customer-service-topics.js" /* webpackChunkName: "component---src-pages-tools-resources-customer-service-topics-js" */),
  "component---src-pages-website-accessibility-policy-js": () => import("./../../../src/pages/website-accessibility-policy.js" /* webpackChunkName: "component---src-pages-website-accessibility-policy-js" */),
  "component---src-pages-why-choose-dfs-being-a-dfs-dealer-js": () => import("./../../../src/pages/why-choose-dfs/being-a-dfs-dealer.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-being-a-dfs-dealer-js" */),
  "component---src-pages-why-choose-dfs-dfs-policies-100-quality-guarantee-js": () => import("./../../../src/pages/why-choose-dfs/dfs-policies/100-quality-guarantee.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-dfs-policies-100-quality-guarantee-js" */),
  "component---src-pages-why-choose-dfs-dfs-policies-dealer-policies-js": () => import("./../../../src/pages/why-choose-dfs/dfs-policies/dealer-policies.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-dfs-policies-dealer-policies-js" */),
  "component---src-pages-why-choose-dfs-dfs-policies-index-js": () => import("./../../../src/pages/why-choose-dfs/dfs-policies/index.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-dfs-policies-index-js" */),
  "component---src-pages-why-choose-dfs-dfs-policies-privacy-policy-js": () => import("./../../../src/pages/why-choose-dfs/dfs-policies/privacy-policy.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-dfs-policies-privacy-policy-js" */),
  "component---src-pages-why-choose-dfs-index-js": () => import("./../../../src/pages/why-choose-dfs/index.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-index-js" */),
  "component---src-pages-why-choose-dfs-manufacturing-distribution-locations-js": () => import("./../../../src/pages/why-choose-dfs/manufacturing-distribution-locations.js" /* webpackChunkName: "component---src-pages-why-choose-dfs-manufacturing-distribution-locations-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-preview-js": () => import("./../../../src/templates/productPreview.js" /* webpackChunkName: "component---src-templates-product-preview-js" */)
}

